import VueRouter from 'vue-router'
// eslint-disable-next-line no-unused-vars
import Site from "@/components/Site";
import Page404 from "@/components/Page404";

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: "/", name:"mainpage", meta: {public: true,}, component: Site, children: [
                {
                    path: '',
                    name: 'setup',
                    meta: {public: true,},
                    component: () => import("@/components/Setup"),
                    children: [
                        {
                            path: '',
                            name: "gamePreset",
                            meta: {public: true,},
                            component: () => import("@/components/gamePreset"),
                        },
                        {
                            path: ':playerN(\\d+)',
                            name: "playerSetup",
                            meta: {public: true,},
                            component: () => import("@/components/playerSetup"),
                        },
                        {
                            path: 'bombs/:playerN(\\d+)',
                            name: 'bombsSetup',
                            meta: {public: true,},
                            component: () => import("@/components/bombsSetup"),
                        },
                    ],
                },
                {
                    path: 'game',
                    name: 'game',
                    meta: {public: true,},
                    component: () => import("@/components/game"),
                },
            ]
        },
        {name: "page404", meta: {public: true,}, component: Page404, path: "*"},
    ],
    linkActiveClass: 'active',
});

export {router}
