import Vue from 'vue';
let players=new Array(2);
for (let i=0;i<2;i++){
    players[i]={
        field:Array.from(Array(10), () => new Array(10)),
        name:'Игрок '+(i+1),
        ships:null,
        bombs: null,
    };
}
let fields=[];
fields.push(Array.from(Array(10), () => new Array(10)));
fields.push(Array.from(Array(10), () => new Array(10)));
let bombOrder=['Переход хода','Потеря капитана','Один за всех','Блиц','#5','#6','#7','#8','#9','#10','#11','#12','#13','#14'];
let targets=['',''];
let shipMaxSize=100;
let gameCode='';
const eventHub = new Vue({data:{players,fields,bombOrder,targets,shipMaxSize,gameCode}});
export default eventHub;
