import Vue from 'vue';
import App from './App.vue';
import VueRouter from "vue-router";
import {router} from './routes.js';
import store from './store';
import 'materialize-css';
require("materialize-css/dist/js/materialize");

require('normalize.css');
require('./assets/app.scss');
require('materialize-css/dist/css/materialize.css');
require('material-design-icons/iconfont/material-icons.css');

Vue.config.productionTip = false
Vue.use(VueRouter);

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
