export default {
    namespaced: true,
    state: {
        config:'',
    },
    actions: {
        save(ctx, d) {
            ctx.commit('saveState',d)
        },
        saveStep(ctx, d) {
            ctx.commit('saveState',d)
        },
        load(ctx) {
            ctx.commit('loadState')
        },
    },
    mutations: {
        saveState(state, d) {
            let config={};
            config['players']=[];
            config['fields']=[];
            config['targets']=d.targets.slice();
            config['players'].push([]);
            config['players'].push([]);
            for (let i=0;i<2;i++) {
                config['players'][i]={};
                config['players'][i]['field'] = d.players[i].field.slice();
                config['players'][i]['name'] = d.players[i].name;
                config['players'][i]['ships'] = d.players[i].ships.slice();
                config['players'][i]['bombs'] = d.players[i].bombs.slice();
                config['fields'].push(d.fields[i].slice());
            }
            state.config=config;
            window.localStorage.setItem('config',JSON.stringify(config));
            console.log(state.config,JSON.stringify(config));
        },
        loadState(state) {
            let configls=window.localStorage.getItem('config');
            state.config=JSON.parse(configls);
            console.log(state.config);
        },
    },
    getters: {
        getConfig(state) {
            return state.config;
        },

    },
}
