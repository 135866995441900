<template>
  <div class="site">
    <router-view></router-view>
  </div>
</template>

<script>
import eventHub from "@/eventHub";
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'Site',
  computed: {
    ...mapGetters("Config", {getConfig: "getConfig",}),
  },
  props: {
  },
  methods:{
    ...mapActions("Config", ["save", "load"]),
  },
  mounted() {
    console.log(eventHub.players[0].ships,window.localStorage.getItem('config'));
    if (!eventHub.players[0].ships && window.localStorage.getItem('config')){
      this.load();
      let c=this.getConfig;
      eventHub.$set(eventHub.players,0,c.players[0]);
      eventHub.$set(eventHub.players,1,c.players[1]);
      eventHub.$set(eventHub.fields,0,c.fields[0]);
      eventHub.$set(eventHub.fields,1,c.fields[1]);
      eventHub.$set(eventHub.targets,0,c.targets[0]);
      eventHub.$set(eventHub.targets,1,c.targets[1]);
    }
  }
}
</script>

<style scoped lang="scss">
</style>
